<script>
import { Mixin } from '@/mixins/util.js'
import { Button,Row, Col,Table, TableColumn } from 'element-ui'
import * as echarts from 'echarts/core';
import {
  DatasetComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent
} from 'echarts/components';
import { LineChart,BarChart,PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  DatasetComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  PieChart,
  BarChart,
  CanvasRenderer,
  MarkPointComponent
]);
export default {
  name: 'income',
  mixins: [Mixin],
  components: {
    'el-button': Button,
    'el-row': Row,
    'el-col': Col,
    'el-table': Table,
    'el-table-column': TableColumn,
  },
  data() {
    return {
      mainChart: null,
      detailChart: null,
      pieChart:null,
      pie1Chart:null,
      data: [],
      dataEmpty: false,
      cardInfo: [
        {
          title: '出荷金額',
          data: 0,
          btn_color: 'bg-primary'
        },
        {
          title: 'キャンセル金額',
          data: 0,
          btn_color: 'bg-success'
        },
        {
          title: '出荷件數',
          data: 0,
          btn_color: 'bg-danger'
        },
        {
          title: 'キャンセル件數',
          data: 0,
          btn_color: 'bg-warning'
        }
      ],
      chartData: null,
      table_95: [],
      table_96: [],
      table_81: [],
      table_84: [],
      h: '460px',
      animation:true,
      highlightSeriesIndex:0,
      highlightdataIndex:0,
      pieChart1_month:null,
      td:{},
      stateList:[],
      colorList:['#535c68','#95afc0','#c7ecee','#1f6185']//f5df85
    }
  },
  computed: {
    type2list(){
      return this.stateList.filter(d=>d.product_type==='2')
    },
    sales() {
      const last = new Date(new Date().getFullYear(),new Date().getMonth()-5)
      return this.table_95
        .filter(d=>{
          return new Date(d[0]) > last
        })
        .map(d => {
          let category = '',source = ''
          if(d[6] === '001_000001_000_00000000'){
            category = 'Cart'
            source = 'Cart'
          }else if(d[6] === '001_000033_000_00000000'){
            category = 'MALL'
            source = 'MOMO'
          }else if(d[6] === '001_000033_001_00000000'){
            category = 'MALL'
            source = 'MOMO廠配'
          }else if(d[6] === '001_000033_002_00000000'){
            category = 'MALL'
            source = 'MO店+'
          }else if(d[6] === '001_000035_000_00000000'){
            category = 'MALL'
            source = 'Yahoo'
          }else if(d[6] === '001_000036_000_00000000'){
            category = 'MALL'
            source = 'Shopee'
          }else if(d[6] === '001_000039_000_00000000  '){
            category = 'MALL'
            source = 'Shopify'
          }else if(d[6] === '001_000041_000_00000000'){
            category = 'MALL'
            source = 'PChome'
          }else if(d[6] === '001_000041_001_00000000'){
            category = 'MALL'
            source = 'PChome廠配'
          }else if(d[6] === '001_000043_000_00000000'){
            category = 'MALL'
            source = 'ETMall'
          }else if(d[6] === '001_000048_000_00000000'){
            category = 'MALL'
            source = 'FriDay'
          }
          else if(d[6].includes('001_000206') || d[6].includes('001_000205')){
            category = 'RETAIL'
            const s = d[6].split('_')
            source = s[s.length-1]
          }
          //[date,sales number,category,source,quantity,currency]
          return [d[0], Math.round(d[3]),category, source,d[6],Math.round(d[1]),d[2]]
        })
    },
    return() {
      return this.table_96.map(d => {
        let category = '',source = ''
          if(d[6] === '001_000001_000_00000000'){
            category = 'Cart'
            source = 'Cart'
          }else if(d[6] === '001_000033_000_00000000'){
            category = 'MALL'
            source = 'MOMO'
          }else if(d[6] === '001_000035_000_00000000'){
            category = 'MALL'
            source = 'Yahoo'
          }else if(d[6] === '001_000036_000_00000000'){
            category = 'MALL'
            source = 'Shopee'
          }else if(d[6] === '001_000039_000_00000000  '){
            category = 'MALL'
            source = 'Shopify'
          }else if(d[6] === '001_000041_000_00000000'){
            category = 'MALL'
            source = 'PChome'
          }else if(d[6] === '001_000043_000_00000000'){
            category = 'MALL'
            source = 'ETMall'
          }else if(d[6] === '001_000048_000_00000000'){
            category = 'MALL'
            source = 'FriDay'
          }
          else if(d[6].includes('001_000206') || d[6].includes('001_000205')){
            category = 'RETAIL'
            const s = d[6].split('_')
            source = s[s.length-1]
          }
        return [d[0], Math.round(d[3]),category, source, d[6],Math.round(d[1]),d[2]]
      })
    },
    datelist(){
      return this.sales.reduce((a,c) => {
        if(!a.includes(c[0])) a.push(c[0])
        return a
      },[])
    },
    sortByCategory(){
      //['日期', '出荷', '売上', 'category','children']
      let list = []
      
      for (const date of this.datelist) {
        for (const category of ['Cart','MALL','RETAIL']) {
          const return_data = this.return.filter(d => d[0] === date && d[2] === category)
          const sale_data = this.sales.filter(d => d[0] === date && d[2] === category)

          let sale_item = sale_data.reduce((a,c)=>{
            a[1]+=c[1]
            a[2]+=c[1]
            const idx = a[4].findIndex(d=>d.name === c[3])
            if(idx === -1){
              a[4].push({
                name:c[3],
                value:c[1]
              })
            }else a[4][idx].value += c[1]
            return a
          },[date,0,0,category,[]])

          let return_item = return_data.reduce((a,c)=>{
            a[2] -=c[1]
            const idx = a[4].findIndex(d=>d.name === c[3])
            if(idx === -1){
              a[4].push({
                name:c[3],
                value:c[1]*-1
              })
            }else a[4][idx].value -= c[1]
            return a
          },sale_item)

          list.push(return_item)
        }
      }
      return list
    },
    sumOfAll(){
      let sum = [],total = 0
      for (const date of this.datelist){
        const dateItem = this.sortByCategory.filter(d=>d[0] === date)
        const sumItem = dateItem.reduce((a,c)=>{
          total+=c[2]
          a[1]+=c[2]
          return a
        },[date,0])

        sum.push(sumItem)
      }
      sum.total = total
      return sum
    },
    sumOfAllMonthByCategory(){
      let list = [['日期', 'Cart', 'MALL', 'RETAIL']]
      for (const date of this.datelist) {
        const item = this.sortByCategory.filter(d=>d[0]===date)
        const listItem = [date,item[0][2],item[1][2],item[2][2]]
        list.push(listItem)
      }
      return list
    },
    sumOfMonthBySource(){
      const data = this.sortByCategory
        .filter(d=>d[0]===this.pieChart1_month)
        .map(d=>{
          return d[4]
        })
      let list = []
      for (const d of data) {
        list.push(...d)
      }
      return list.filter(d=>d.value!==0)
    },
    sumOfCategoryByMonth(){
      return this.sortByCategory
        .filter(d=>d[0]===this.pieChart1_month)
        .map(d=>{
          return {
            name:d[3],
            value:d[2]
          }
        })
    },
    sumOfMonthByLC(){
      const LC = this.sortByCategory
        .find(d=>d[0]===this.pieChart1_month && d[3]==='Cart')
      if(LC===undefined) return []
      return LC[4].sort((a,b)=>b.value-a.value).map(d=>[d.name,d.value])
    },
    sumOfMonthByEC(){
      const MALL = this.sortByCategory
        .find(d=>d[0]===this.pieChart1_month && d[3]==='MALL')
      if(MALL===undefined) return []
      return MALL[4].sort((a,b)=>b.value-a.value).map(d=>[d.name,d.value])
    },
    sumOfMonthByRETAIL(){
      const RETAIL = this.sortByCategory
        .find(d=>d[0]===this.pieChart1_month && d[3]==='RETAIL')
      if(RETAIL===undefined) return []
      return RETAIL[4].sort((a,b)=>b.value-a.value).map(d=>[d.name,d.value])
    },
    cardData(){
      const refund_data = this.return.filter(d => d[0] === this.pieChart1_month)
      const sale_data = this.sales.filter(d => d[0] === this.pieChart1_month)

      let sale_item = sale_data.reduce((a,c)=>{
        a.sales+=c[1]
        a.sale_quatity+=c[5]
        a.currency = c[6]
        return a
      },{sales:0,sale_quatity:0,refund:0,refund_quantity:0,currency:''})

      let refund_item = refund_data.reduce((a,c)=>{
        a.refund+=c[1]
        a.refund_quantity+=c[5]
        return a
      },sale_item)
      return refund_item
    },
    tableData(){
      let data = []
      const table_source = this.td[this.pieChart1_month]
      if(table_source===undefined) return data

      const {table81,table84} = table_source
      
      for (const t81_idx in table81) {
        // const pre_month_instock = table81[t81_idx][2]*1 //前月末在庫數
        // const cur_month_in = table81[t81_idx][3]*1      //入荷數
        // const cur_month_reject = table81[t81_idx][7]*1  //理論拒收
        // const cur_month_reurnG = table81[t81_idx][11]*1 //返品(良品)

        // const cur_month_broken = table81[t81_idx][4]*-1  //破損數
        // const cur_month_out = table81[t81_idx][5]*-1     //理論出荷
        // const cur_month_moveG = table83[t81_idx][2]*-1 //良品在庫移動

        // const stock = pre_month_instock + cur_month_in + cur_month_reject + cur_month_reurnG + cur_month_broken + cur_month_out
        const obj = {
          product_name:table81[t81_idx][1],
          product_code:table81[t81_idx][0],
          predict_instock_weekly:table84[t81_idx][0],
          stock:table81[t81_idx][13]
        }
        data.push(obj)
      }
      return data.sort((a,b)=>b.stock-a.stock).slice(0, 5).filter(d=>{
        return this.type2list.some(t=>t.product_code===d.product_code)
      })
    }
  },
  methods: {
    bgColor(colorIndex){
      return {
        borderTop:`5px solid ${this.colorList[colorIndex]}`,
        borderBottom:`5px solid ${this.colorList[colorIndex]}`,
      }
    },
    async mainChartClickHandler({name}) {
      if(this.td[name]===undefined){
        const date = name.split('-')
        await this.getChartDataWithTimeOut(date[0],date[1],'81,84')
      }
      this.pieChart1_month = name
      this.drawDetailChart()
      // this.drawPieChart()
      // this.drawPie1Chart()
    },
    drawMainChart(){
      const option = {
        legend: { show:true },
        tooltip: { show:true },
        dataset: [ {source:this.sumOfAll},{source:this.sumOfAllMonthByCategory}],
        xAxis: { type: 'category'},
        yAxis: {},
        grid: { left: '5%',right: '3%'},
        series: [
          { 
            datasetIndex:1,
            type: 'bar',
            label: {
              show: true,
              fontSize:10,
              position: 'inside',
              formatter:({data})=>{
                if(data[1] === 0) return ''
                return data[1].toLocaleString()
              }
            },
            itemStyle: {
              color:this.colorList[0]
            },
            stack:'MDFK',
            // barMinHeight:20,
          },
          { 
            datasetIndex:1,
            type: 'bar',
            label: {
              show: true,
              fontSize:10,
              position: 'inside',
              formatter:({data})=>{
                if(data[2] === 0) return ''
                return data[2].toLocaleString()
              }
            },
            itemStyle: {
              color:this.colorList[1]
            },
            stack:'MDFK',
            // barMinHeight:20,
          },
          { 
            datasetIndex:1,
            type: 'bar',
            label: {
              show: true,
              fontSize:10,
              position:'inside',
              formatter:({data})=>{
                if(data[3] === 0) return ''
                return data[3].toLocaleString()
              }
            },
            itemStyle: {
              color:this.colorList[2]
            },
            stack:'MDFK',
            // barMinHeight:20,
          },
        ],
      }
      this.mainChart.setOption(option)
    },
    drawDetailChart(){
      const option = {
        legend: { show:false },
        tooltip: { show:false },
        dataset: [ {source:this.sumOfMonthBySource}],
        xAxis: 
          { type: 'category',axisLabel: {interval: 0,rotate:18,align:'center',margin:25}},
        yAxis: {},
        grid: { bottom: '15%',left: '7%',right: '3%' },
        series: [
          {
            type: 'bar',
            label: {
              show: true,
              fontSize:12,
              fontWeight:'bold',
              position: 'top',
              formatter:(d)=>d.data.value.toLocaleString()
            },
            itemStyle: {
              color: ({data})=>{
                const {name} = data
                let color = this.colorList[2]
                switch(name){
                  case 'Cart':
                    color = this.colorList[0]
                    break
                  case 'MOMO':
                  case 'Yahoo':
                  case 'Shopee':
                  case 'Shopify':
                  case 'PChome':
                  case 'ETMall':
                  case 'FriDay':
                    color = this.colorList[1]
                    break
                  default:
                    break
                }
                return color
              }
            },
            barCategoryGap:'0%',
            barGap:'0%',
            barMinHeight:20,
            barMaxWidth:30,
          }
        ]
      }
      this.detailChart.setOption(option)
    },
    drawPieChart(){
      const pie_data = this.sumOfCategoryByMonth.map(d=>{
        let value = d.value
        if(d.value<0)
          value = d.value*-1
        return {
          name:d.name,
          value,
          nega:d.value
        }
      })
      const option = {
        legend: { 
          show:true,
          orient:'vertical',
          top:'middle'
        },
        tooltip: { 
          show:true,
          formatter:(params)=>{
            if(params.data.nega<0) return params.data.nega.toLocaleString()
            else return params.data.value.toLocaleString()
          }
        },
        xAxis: { type: 'category'},
        yAxis: {},
        series: [
          {
            type:'pie',
            minAngle:15,
            color:this.colorList,
            radius: ["55%", "65%"],
            label:{
              show:false,
              position:'outside'
            },
            labelLine:{
              lineStyle:{
                width:5
              }
            },
            itemStyle: {
              borderRadius: 10,
              borderColor: '#eee',
              borderWidth: 2
            },
            data:pie_data,
          }
        ]
      }
      this.pieChart.setOption(option)
    },
    drawPie1Chart(){
      const pie_data = this.sumOfCategoryByMonth.map(d=>{
        let value = d.value
        if(d.value<0)
          value = 0
        return {
          name:d.name,
          value,
          nega:d.value
        }
      })
      const option = {
        legend: { 
          show:true,
          orient:'vertical',
          top:'middle'
        },
        tooltip: { 
          show:true,
          formatter:(params)=>{
            if(params.data.nega<0) return params.data.nega.toLocaleString()
            else return params.data.value.toLocaleString()
          }
        },
        xAxis: { type: 'category'},
        yAxis: {},
        series: [
          {
            type:'pie',
            minAngle:15,
            color:this.colorList,
            radius: ["55%", "65%"],
            label:{
              show:false,
              position:'outside'
            },
            labelLine:{
              lineStyle:{
                width:5
              }
            },
            itemStyle: {
              borderRadius: 10,
              borderColor: '#eee',
              borderWidth: 2
            },
            data:pie_data,
          }
        ]
      }
      this.pie1Chart.setOption(option)
    },
    async getChartDataWithTimeOut(yyyy,mm,params = '81,84,95,96') {
      if(yyyy === undefined) yyyy = new Date().getFullYear()
      if(mm === undefined) mm = new Date().getMonth() + 1
      const result = this.fetchWithTimeout(
        yyyy,
        mm,
        params
      )
      // this.$eventBus.$emit('loadingHandler', true)
      await result
        .then(res => res.text())
        .then(rep => {
          rep = JSON.parse(this.RemoveNonValidJSONChars(rep))
          this.$eventBus.$emit('loadingHandler', false)
          // console.log(rep)
          if (rep.errno !== '0') {
            this.$eventBus.$emit('logOutHandler', true)
            this.ToLoginPage()
          } else {
            //in 96 out 95
            const keys = Object.keys(rep).filter(item => !isNaN(item))
            keys.forEach(key => {
              this[`table_${key}`] = rep[key]
            })
            if(this.td[`${yyyy}-${mm}`]===undefined) {
              this.td[`${yyyy}-${mm}`] = {}
              this.td[`${yyyy}-${mm}`]['table81'] =this.table_81
              this.td[`${yyyy}-${mm}`]['table83'] =this.table_83
              this.td[`${yyyy}-${mm}`]['table84'] =this.table_84
            }
            if (this.table_95.length === 0 && this.table_96.length === 0)
              this.dataEmpty = true
          }
        })
        .catch(() => {
          // console.log(err)
          this.$eventBus.$emit('loadingHandler', false)
          this.$eventBus.$emit('serverDownHandler', true)
          this.ToLoginPage()
        })
    },
    ToLoginPage() {
      this.timer = setTimeout(() => {
        this.$router.push({
          name: 'login',
          params: { t: '123' }
        })
      }, 2500)
    },
    async getSetList(){
      const s_id = sessionStorage.getItem('session_id')
      const s_uid = parseInt(
        sessionStorage.getItem('pub_permissions').substr(1)
      )
      const url = `https://www.bamb.asia/suppliers/get_all_prd_status`
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "text/plain;charset=UTF-8",
        },
        body: `sessionid=${s_id}&
        supplier_id=${s_uid}`,
      };

      await fetch(url, config)
        .then((res) => res.text())
        .then((rep) => {
          rep = JSON.parse(this.RemoveNonValidJSONChars(rep))
          if (rep.errno === "1") {
            this.stateList = rep.records.map(d=>{
              return {
                product_code:this.Mosaic(d[0],sessionStorage.getItem('session_id')),
                product_name:this.Mosaic(d[1],sessionStorage.getItem('session_id')),
                product_state:d[2]==='1'?true:false,
                product_type:d[3],
              }
            })
          }
        })
    },
  },
  created() {
    document.title = 'Dashborad'
    if (window.screen.height > 800) this.h = '760px'
  },
  async mounted() {
    this.getSetList()

    this.mainChart = echarts.init(document.getElementById('mainChart'))
    this.detailChart = echarts.init(document.getElementById('detailChart'))
    // this.pieChart = echarts.init(document.getElementById('pieChart'))
    // this.pie1Chart = echarts.init(document.getElementById('pie1Chart'))
    await this.getChartDataWithTimeOut()

    const date = new Date()
    const y = date.getFullYear()
    const m = date.getMonth()+1
    const now = m >= 10 ? `${y}-${m}` :`${y}-0${m}`
    this.pieChart1_month = now

    this.drawMainChart()
    this.drawDetailChart()
    // this.drawPieChart()
    // this.drawPie1Chart()

    const menu = document.querySelector('.menu')
    const ham = document.querySelector('.ham')
    menu.style.left = '-320px'
    ham.style.display = 'block'
    window.onresize = () => {
      this.mainChart.resize();
    }
    this.mainChart.on('click', this.mainChartClickHandler)
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped>

</style>

